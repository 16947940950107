<template>
  <v-container>
    <v-card elevation="10" class="control-card">
      <v-card-title class="text-center">Adjust Heating</v-card-title>

      <v-card-text>
        <v-select v-if="heatersHardware?.length > 1" label="Select Heater" :items="heatersHardware" return-object
          item-value="serialNumber" item-title="serialNumber" v-model="selectedHeater" variant="solo-filled"></v-select>
        <template v-if="selectedHeater">
          <div v-if="selectedHeater.hasTemperature" class="slider-section">
            <div class="slider-heading">
              <v-icon class="slider-icon">mdi-thermometer</v-icon>
              <span>Adjust Temperature</span>
            </div>
            <div class="slider-container">
              <button @click="decreaseTemperature" class="icon-btn">
                <v-icon>mdi-minus</v-icon>
              </button>
              <v-slider v-model="temperatureValue" :step="temperatureSteps" :color="temperatureColor"
                :min="temperatureMin" :max="temperatureMax" class="slider" thumb-label="always"></v-slider>
              <button @click="increaseTemperature" class="icon-btn">
                <v-icon>mdi-plus</v-icon>
              </button>
            </div>
          </div>
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent, ref, computed, onMounted, watch } from 'vue'

export default defineComponent({
  name: 'HeatingControl',
  props: {
    heaters: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props) {
    const selectedHeater = ref(null)
    const temperatureValue = ref(0) // Default temperature slider value
    const temperatureSteps = ref(5) // Adjust step value as needed
    const temperatureMin = ref(-30)
    const temperatureMax = ref(30)

    const heatersHardware = computed(() => props.heaters)

    const temperatureColor = computed(() =>
      temperatureValue.value < 0 ? 'blue' : 'red'
    )

    const decreaseTemperature = () => {
      temperatureValue.value = Math.max(
        temperatureMin.value,
        temperatureValue.value - temperatureSteps.value
      )
    }

    const increaseTemperature = () => {
      temperatureValue.value = Math.min(
        temperatureMax.value,
        temperatureValue.value + temperatureSteps.value
      )
    }

    const updateFeatures = heater => {
      if (heater?.hasTemperature) {
        temperatureValue.value = heater.temperatureOptions.min
        temperatureMin.value = heater.temperatureOptions.min
        temperatureMax.value = heater.temperatureOptions.max
        temperatureSteps.value = heater.temperatureOptions.steps
      }
    }

    onMounted(() => {
      if (heatersHardware.value?.length > 0) {
        selectedHeater.value = heatersHardware.value[0]
        updateFeatures(heatersHardware.value[0])
      }
    })

    watch(
      selectedHeater,
      newHeater => {
        if (newHeater) {
          updateFeatures(newHeater)
        }
      },
      { immediate: true }
    )

    return {
      heatersHardware,
      selectedHeater,
      temperatureValue,
      decreaseTemperature,
      increaseTemperature,
      temperatureSteps,
      temperatureMin,
      temperatureMax,
      temperatureColor,
    }
  },
})
</script>

<style scoped>
.control-card {
  margin-top: 20px;
  padding: 20px;
  border-radius: 15px;
  background-color: #f5f5f5;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.control-card:hover {
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

.slider-section {
  margin-top: 20px;
}

.slider-heading {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  /* Increased margin for better spacing */
}

.slider-heading .slider-icon {
  margin-right: 8px;
  font-size: 24px;
}

.slider-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Changed to space-between for better alignment */
  gap: 10px;
}

.slider {
  flex: 1;
  /* Added flex property to make slider take up remaining space */
  margin: 0 10px;
}

.icon-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
  margin-bottom: 20px;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.icon-btn:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.icon-btn v-icon {
  font-size: 18px;
}

.icon-btn:hover:enabled {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

@media (max-width: 600px) {
  .slider {
    width: 200px;
  }

  .icon-btn {
    font-size: 10px;
    padding: 3px 5px;
  }

  .icon-btn v-icon {
    font-size: 14px;
  }

  .slider-heading .slider-icon {
    font-size: 18px;
  }
}
</style>
<!--HeatingControl Component

This component provides a user interface for adjusting the heating settings of various heaters. The main functionalities and interactions available to users are as follows:

The component displays a card titled "Adjust Heating" which contains all the controls for managing the heating settings.

If there are multiple heaters available, a dropdown menu (v-select) is shown, allowing users to select a specific heater. The dropdown lists the heaters by their serial numbers.

Once a heater is selected, if the selected heater supports temperature adjustments, a temperature control section is displayed. This section includes:
- A heading with a thermometer icon and the text "Adjust Temperature".
- A slider for adjusting the temperature. The slider's range, step value, and color are dynamically set based on the selected heater's capabilities.
- Two buttons on either side of the slider for fine-tuning the temperature. The left button decreases the temperature, and the right button increases it.

The slider's color changes based on the temperature value: it turns blue for temperatures below zero and red for temperatures above zero, providing visual feedback to the user.

The component initializes by selecting the first heater from the list and setting up the temperature control based on its properties. If the selected heater changes, the temperature control updates accordingly.

The component is responsive and adjusts its layout for smaller screens, ensuring a user-friendly experience on mobile devices.

This component does not include any alert mechanisms like success, warning, or error alerts. However, it provides immediate visual feedback through the slider's color change and the enabled/disabled state of the buttons.
-->
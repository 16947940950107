<template>
  <v-container>
    <v-card elevation="10" class="control-card">
      <v-card-title class="text-center">Adjust lights</v-card-title>
      <v-card-text>
        <v-select v-if="lightHardware?.length > 1" label="Select Light" :items="lightHardware" return-object
          item-value="serialNumber" item-title="serialNumber" v-model="selectedLight" variant="solo-filled"></v-select>
        <div v-if="hasSwitch" class="main-switch-container">
          <CustomSwitch v-model="mainSwitchState" />
        </div>
        <div :class="{ 'disabled-container': hasSwitch && !mainSwitchState }">
          <div class="slider-container" v-if="hasBrightness">
            <h2>Adjust Brightness</h2>
            <BrightnessSlider :value="brightnessValue" :steps="brightnessSteps"
              @brightness-change="handleBrightnessChange" :disabled="hasSwitch && !mainSwitchState" />
            <div class="value-display">{{ brightnessValue }}</div>
          </div>
          <div class="slider-container" v-if="hasLightTemp">
            <h2>Adjust Temperature</h2>
            <LightTempSlider :value="temperatureValue" :steps="temperatureSteps"
              @temperature-change="handleTemperatureChange" :disabled="hasSwitch && !mainSwitchState" />
            <div class="value-display">{{ temperatureValue }}</div>
          </div>
          <div class="slider-container" v-if="selectedLight?.hasScene === true">
            <SceneSlider :sceneOptions="selectedLight?.sceneOptions" :updateSceneValue="updateSceneValue"
              :disabled="hasSwitch && !mainSwitchState" />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent, ref, computed, onMounted, watch } from 'vue'
import BrightnessSlider from '@/components/general/ChildComponents/BrightnessSlider.vue'
import LightTempSlider from '@/components/general/ChildComponents/LightTempSlider.vue'
import SceneSlider from '@/components/general/ChildComponents/SceneSlider'
import CustomSwitch from '@/components/general/ChildComponents/CustomSwitch.vue'

export default defineComponent({
  name: 'LightControl',
  components: {
    BrightnessSlider,
    LightTempSlider,
    SceneSlider,
    CustomSwitch,
  },
  props: {
    light: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const brightnessValue = ref(0)
    const temperatureValue = ref(0)
    const temperatureSteps = ref(1)
    const brightnessSteps = ref(1)
    const sceneOptions = ref([
      { id: '1', value: '0%', label: '0%' },
      { id: '2', value: '20%', label: '20%' },
      { id: '3', value: '40%', label: '40%' },
      { id: '4', value: '60%', label: '60%' },
      { id: '5', value: '80%', label: '80%' },
      { id: '6', value: 'Automatic', label: 'Automatic' },
      { id: '7', value: 'Off', label: 'Off' },
    ])
    const mainSwitchState = ref(false)
    const hasSwitch = ref(false)
    const selectedScene = ref('0%')

    const lightHardware = computed(() => props.light)

    const selectedLight = ref({})

    const hasBrightness = ref(false)
    const hasScene = ref(false)
    const hasLightTemp = ref(false)

    const handleBrightnessChange = value => {
      brightnessValue.value = value
    }

    const handleTemperatureChange = value => {
      temperatureValue.value = value
    }

    const updateSceneValue = setSceneValue => {
      selectedScene.value = setSceneValue
    }

    const updateFeatures = light => {
      let lightActions = light?.actions || []

      hasBrightness.value = lightActions.some(
        action => action.actionType === 'brightness'
      )
      hasLightTemp.value = lightActions.some(
        action => action.actionType === 'temperature'
      )
      hasScene.value = lightActions.some(
        action => action.actionType === 'scene'
      )
      hasSwitch.value = lightActions.some(
        action => action.actionType === 'switch'
      )
      if (hasBrightness.value) {
        brightnessSteps.value = light.brightnessOptions.steps
        brightnessValue.value = light.brightnessOptions.min
      }
      if (hasLightTemp.value) {
        temperatureSteps.value = light.temperatureOptions.steps
        temperatureValue.value = light.temperatureOptions.min
      }
    }

    watch(
      selectedLight,
      newLight => {
        if (newLight) {
          updateFeatures(newLight)
        }
      },
      { immediate: true }
    )

    onMounted(() => {
      let lights = JSON.parse(JSON.stringify(lightHardware.value))
      let lightSelected = lights[0]
      selectedLight.value = lightSelected
      updateFeatures(lightSelected)
    })

    return {
      hasBrightness,
      hasScene,
      hasLightTemp,
      brightnessValue,
      temperatureValue,
      temperatureSteps,
      brightnessSteps,
      sceneOptions,
      mainSwitchState,
      hasSwitch,
      handleBrightnessChange,
      handleTemperatureChange,
      updateSceneValue,
      lightHardware,
      selectedLight,
    }
  },
})
</script>

<style scoped>
.control-card {
  margin-top: 20px;
  padding: 20px;
  border-radius: 15px;
  background-color: #f5f5f5;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.control-card:hover {
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

.main-switch-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.slider-container {
  margin-bottom: 20px;
}

.slider-container h2 {
  margin-bottom: 10px;
}

.value-display {
  display: block;
  margin: 0 auto;
  padding: 0.5em 1em;
  border-radius: 1em;
  width: max-content;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 1.2em;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.disabled-container {
  opacity: 0.5;
  pointer-events: none;
}
</style>
<!-- LightControl Component

This component provides a user interface for controlling various aspects of smart lights. Users can interact with the following features:

1. Light Selection: If there are multiple lights available, users can select a specific light from a dropdown menu. This dropdown is populated with the available lights, each identified by its serial number.

2. Main Switch: If the selected light has a switch feature, a main switch is displayed. Users can toggle this switch to turn the light on or off. When the switch is off, other controls are disabled and visually dimmed to indicate they are inactive.

3. Brightness Adjustment: If the selected light supports brightness control, a slider is displayed allowing users to adjust the brightness level. The current brightness value is shown below the slider. The slider is disabled if the main switch is off.

4. Temperature Adjustment: If the selected light supports temperature control, a slider is displayed allowing users to adjust the light temperature. The current temperature value is shown below the slider. The slider is disabled if the main switch is off.

5. Scene Selection: If the selected light supports scene control, a scene slider is displayed. Users can choose from predefined scenes to set the light to a specific mode. The slider is disabled if the main switch is off.

Visual Feedback:
- The component provides visual feedback by disabling and dimming controls when they are not active.
- The current values for brightness and temperature are displayed prominently below their respective sliders.
- The component uses a card layout with elevation and hover effects to enhance the visual appeal and provide a clear, interactive experience.

Overall, this component enhances the user experience by providing intuitive controls for managing smart lights, making it easy to adjust settings and see immediate feedback on their actions. -->

<template>
  <div class="incident-creation">
    <!-- Toolbar -->
    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <v-btn icon>
        <v-icon size="40" @click="goBack">mdi-arrow-left-bold-circle</v-icon>
      </v-btn>
      <v-toolbar-title class="custom-toolbar-title">
        {{ 'Incidents' }}
      </v-toolbar-title>

      <!-- Refresh button added here -->
      <v-btn class="mr-1" icon small @click="refresh">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>

      <!-- Create button -->
      <!-- <button class="custom-create-incident-btn" @click="openCreateDialog">
        <v-icon left>mdi-plus-circle-outline</v-icon>
        Create
      </button> -->
      <v-btn size="small" class="pulse-animation" variant="tonal" @click="openCreateDialog" color="black">Create</v-btn>
    </v-toolbar>
    <Loader :loading="showLoader" />
    <UserIncidents :incidents="incidents" :clickHandler="handleIncident" />
    <v-dialog v-model="createDialog" max-width="600px">
      <v-card>
        <v-card-title>Create an Incident</v-card-title>
        <v-card-subtitle>Send an incident or damage to the facility management.</v-card-subtitle>
        <v-card-text>
          <v-form>
            <v-text-field variant="underlined" label="Set a Location" prepend-icon="mdi-map-marker" readonly
              @click="openMap" v-model="locationText" />
            <v-select variant="underlined" label="Select a Category" :items="categories"
              prepend-icon="mdi-format-list-bulleted" v-model="SELECTED_CATEGORY" />
            <v-textarea variant="underlined" label="Describe the Incident" prepend-icon="mdi-text" v-model="description"
              required />
            <v-file-input counter show-size variant="underlined" v-model="imageFiles" label="Upload Image"
              prepend-icon="mdi-camera" multiple accept="image/png, image/jpeg">
              <template v-slot:selection="{ fileNames }">
                <template v-for="fileName in fileNames" :key="fileName">
                  <v-chip class="me-2" color="primary" size="small" label>
                    {{ fileName?.substring(0, 30) }}
                  </v-chip>
                </template>
              </template>
            </v-file-input>
            <v-row v-if="imageFiles.length" class="mt-3">
              <v-col v-for="(file, index) in imageFiles" :key="index" cols="4"
                class="d-flex align-center justify-center">
                <v-img :src="getImageURL(file)" alt="Image preview" max-height="100" max-width="100"
                  class="thumbnail-image" contain />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="cancelIncident">Cancel</v-btn>
          <v-btn v-if="isEditMode" variant="tonal" color="red" @click="deleteIncident">Delete</v-btn>
          <v-btn v-if="isEditMode === false" color="primary" :disabled="!canSubmit"
            @click="submitIncident">Submit</v-btn>
          <v-btn v-else color="primary" :disabled="!canSubmit" @click="editIncident">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Map Dialog -->
    <MapView ref="mapView" :dragMarker="true" @onPositionSelected="setLocation" />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import imageSrcDesktop from '@/assets/General/no_booking_desktop.webp'
import imageSrcMobile from '@/assets/General/no_booking_mobile.webp'
import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
import UserIncidents from '@/components/general/ChildComponents/UserIncidents.vue'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'
import { getStoredItem } from '@/services/utils.js'

import {
  getFloorById,
  getFloors,
  getBuildings,
  getPoiCategories,
  getPoisByCategoryId,
  createIncident,
  getIncidentCategories,
  moveIncidentsToCategory,
  updateIncidentById,
  deleteIncidentFiles,
  deleteIncidentById,
  getIncidentById,
  getFile,
  getFileInfo,
  deleteIncidentsFull,
  getIncidentsByUser,
  linkIncidentToCategory,
} from '@/controllers/incidentsAxiosBypasser'
import { isStaingOrQa, postMultipleFiles, linkIncidentToImage } from '@/controllers/BaseController'
import MapView from '@/components/general/IncidentsMapView.vue'
import { useOAuthService } from '@/services/OAuthService'
import Loader from '@/components/general/Loader.vue'
import { showToast } from '@/services/utils'

import lodash from 'lodash'

export default defineComponent({
  name: 'Incidents',
  components: { MapView, Loader, UserIncidents },
  setup() {
    const logo = ref('')
    const oAuthService = useOAuthService()
    const router = useRouter()
    const selectedIncident = ref(null)
    const incidents = ref([]) // Fetch this data from an API or state
    const createDialog = ref(false)
    const SELECTED_CATEGORY = ref(null)
    const showLoader = ref(false)
    const description = ref('')
    const originalValues = ref({})
    const mapView = ref(null)
    const location = ref('')
    const isEditMode = ref(false)
    const locationText = ref('')
    const imageFiles = ref([])
    const categories = ref(['Electrical', 'Plumbing', 'Other']) // Example categories
    const isMobile = computed(() => window.innerWidth <= 600)
    const allIncidentCategories = ref([])
    const SELECTED_BUILDING = ref(null)
    const SELECTED_FLOOR = ref(null)
    const canSubmit = computed(() => {
      return locationText.value && SELECTED_CATEGORY.value && description.value
    })

    const deleteIncident = async () => {
      let incident = JSON.parse(JSON.stringify(selectedIncident.value))
      let incidentId = incident.id
      createDialog.value = false

      // Confirmation dialog with description
      const confirmation = await Swal.fire({
        title: 'Delete Incident',
        text: `Do you want to delete the incident: "${incident.description}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d2f8d2',
        cancelButtonColor: '#fbe5e3',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel',
      })

      // If user cancels, exit the function
      if (!confirmation.isConfirmed) {
        createDialog.value = true
        return
      }

      showLoader.value = true

      try {
        // Show loading indication
        Swal.fire({
          title: 'Deleting Incident...',
          text: 'Please wait while the incident is being deleted.',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading()
          },
        })

        // Call the delete function
        await deleteIncidentById(incidentId)

        // On successful deletion
        Swal.close()
        Swal.fire({
          icon: 'success',
          title: 'Incident Deleted',
          text: 'The incident has been successfully deleted.',
          confirmButtonColor: '#d2f8d2',
          confirmButtonText: 'OK',
        }).then(() => {
          refresh()
        })
      } catch (error) {
        console.error('Error deleting incident:', error)
        Swal.fire({
          icon: 'error',
          title: 'Delete Failed',
          text: 'An error occurred while deleting the incident. Please try again.',
        })
      } finally {
        showLoader.value = false
      }
    }

    const handleIncident = async incident => {
      incident = JSON.parse(JSON.stringify(incident))
      locationText.value = incident.location.locationName
      SELECTED_CATEGORY.value = incident.incidentCategoryName
      description.value = incident.description
      SELECTED_BUILDING.value = incident.building
      SELECTED_FLOOR.value = incident.location.floor
      location.value = { lat: incident.location.latitude, long: incident.location.longitude }

      // Fetch and set image files if any exist
      if (lodash.some(incident.files.entities, { type: 'image' })) {
        const imagesFileIds = incident.files.entities.filter(file => file.type === 'image')
        imageFiles.value = await Promise.all(
          imagesFileIds.map(async file => {
            const fileBlob = await getFile(file.id)
            const fileInfo = await getFileInfo(file.id)
            return new File([fileBlob], fileInfo.fileName, { type: fileInfo.mimeType })
          })
        )
      } else {
        imageFiles.value = []
      }

      // Store original values for comparison
      originalValues.value = {
        locationText: locationText.value,
        SELECTED_CATEGORY: SELECTED_CATEGORY.value,
        description: description.value,
        imageFiles: [...imageFiles.value], // Clone image files array
      }

      selectedIncident.value = incident

      isEditMode.value = true
      createDialog.value = true
    }
    const isLocationTextChanged = computed(() => originalValues.value.locationText !== locationText.value)

    const isCategoryChanged = computed(() => originalValues.value.SELECTED_CATEGORY !== SELECTED_CATEGORY.value)

    const isDescriptionChanged = computed(() => originalValues.value.description !== description.value)

    const areImagesChanged = () => {
      if (originalValues.value.imageFiles.length !== imageFiles.value.length) {
        return true
      }
      return !originalValues.value.imageFiles.every((file, index) => {
        return file.name === imageFiles.value[index].name && file.size === imageFiles.value[index].size
      })
    }

    const getImageURL = file => {
      if (file instanceof File) {
        return URL.createObjectURL(file)
      }
      return null
    }

    onMounted(async () => {
      Swal.fire({
        title: 'Loading...',
        text: 'Please wait while we load data.',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading()
        },
      })

      try {
        // Initiate parallel data requests for efficiency
        const [logoImage, isPIA, profile, buildings, allCategories] = await Promise.all([
          getToolbarLogoImage(),
          isStaingOrQa(),
          oAuthService.getUserProfile(),
          getBuildings(),
          getIncidentCategories(),
        ])

        // Assign fetched logo
        logo.value = logoImage

        // Filter and sort active categories
        allIncidentCategories.value = allCategories.filter(category => category.active)
        categories.value = lodash.orderBy(
          allIncidentCategories.value.map(category => category.name),
          [category => category.toLowerCase()],
          ['asc']
        )

        // Determine building based on PIA status
        const selectedBuildingName = isPIA ? buildings.find(b => b.name === 'Teichhaus')?.name : getStoredItem('building')
        const selectedBuilding = buildings.find(b => b.name === selectedBuildingName)
        SELECTED_BUILDING.value = selectedBuilding

        // Fetch user incidents using profile email
        const userIncidents = await getIncidentsByUser(profile.email)
        incidents.value = userIncidents

        Swal.close() // Close the Swal loader on successful completion
      } catch (error) {
        console.error('Error loading data:', error)
        Swal.fire({
          icon: 'error',
          title: 'Error Loading Data',
          text: 'There was an issue loading the data. Please try again later.',
        })
      }
    })

    const hasIncidents = computed(() => incidents.value.length > 0)

    const openCreateDialog = () => {
      SELECTED_CATEGORY.value = null
      description.value = ''
      locationText.value = ''
      imageFiles.value = []
      location.value = ''
      createDialog.value = true
    }

    const refresh = async () => {
      // Display Swal loader while deleting incidents
      Swal.fire({
        title: 'Refreshing...',
        text: 'Please wait while we refresh the incidents list.',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading()
        },
      })

      try {
        // Call the function to delete all stored incidents and incident categories
        await deleteIncidentsFull()

        // Reload the current page to refresh the incidents list
        router.go(0)
      } catch (error) {
        console.error('Error refreshing incidents:', error)
        Swal.fire({
          icon: 'error',
          title: 'Refresh Failed',
          text: 'There was an issue refreshing the incidents. Please try again.',
        })
      }
    }

    const openMap = async () => {
      let CATEGORIES = await getPoiCategories()
      let POIS = []
      for (let category of CATEGORIES) {
        let categoryPois = await getPoisByCategoryId(category.id)
        Array.prototype.push.apply(POIS, categoryPois)
      }
      if (location.value) {
        mapView.value.openMap(SELECTED_BUILDING.value, POIS, CATEGORIES, location.value)
      } else {
        mapView.value.openMap(SELECTED_BUILDING.value, POIS, CATEGORIES)
      }
    }
    const setLocation = ({ coordinates, floor, locationName }) => {
      // Set the coordinates in the location field
      if (!coordinates) return
      coordinates = JSON.parse(JSON.stringify(coordinates))
      floor = JSON.parse(JSON.stringify(floor))
      locationName = JSON.parse(JSON.stringify(locationName))
      location.value = { lat: coordinates[0], long: coordinates[1] }
      locationText.value = locationName
      SELECTED_FLOOR.value = floor
    }

    const isFormModified = computed(() => {
      return isLocationTextChanged.value || isCategoryChanged.value || isDescriptionChanged.value || areImagesChanged()
    })

    const resizeImage = (file, maxWidth = 750, maxHeight = 498) => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = URL.createObjectURL(file)
        img.onload = () => {
          const canvas = document.createElement('canvas')
          let width = img.width
          let height = img.height

          if (width > maxWidth) {
            height *= maxWidth / width
            width = maxWidth
          }
          if (height > maxHeight) {
            width *= maxHeight / height
            height = maxHeight
          }

          canvas.width = width
          canvas.height = height

          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)

          canvas.toBlob(blob => {
            if (blob) {
              resolve(new File([blob], file.name, { type: file.type })) // Pass original file name
            } else {
              reject(new Error('Image resize failed.'))
            }
          }, 'image/png')
        }
        img.onerror = () => reject(new Error('Image load failed.'))
      })
    }

    const cancelIncident = () => {
      createDialog.value = false
      isEditMode.value = false
    }

    const editIncident = async () => {
      showLoader.value = true
      createDialog.value = false
      isEditMode.value = false

      // Check if there are any changes before proceeding
      if (!isFormModified.value) {
        Swal.fire({
          icon: 'info',
          title: 'No Changes Detected',
          text: 'No changes were made to the incident.',
        })
        showLoader.value = false
        return
      }

      Swal.fire({
        title: 'Updating Incident...',
        text: 'Please wait while we update the incident details.',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading()
        },
      })

      try {
        // Fetch the latest version of the incident

        const latestIncident = await getIncidentById(selectedIncident.value?.id)
        const version = latestIncident.version // Get version from latest data
        // Prepare payload with version and updated fields
        let categoryId = allIncidentCategories.value.find(category => category.name === SELECTED_CATEGORY.value)?.id

        let updatePayload = {
          version, // Include the latest version
          description: description.value,
          location: {
            floorId: location.value.floorId,
            latitude: location.value.lat,
            longitude: location.value.long,
            locationName: locationText.value,
          },
          incidentCategoryId: categoryId,
        }

        // Call updateIncidentById with the latest versioned payload
        const updatedIncident = await updateIncidentById(selectedIncident.value?.id, updatePayload)

        if (updatedIncident && updatedIncident.id) {
          // Link to the new category if it has changed
          if (isCategoryChanged.value) {
            let destinationCategoryId = allIncidentCategories.value.find(category => category.name === SELECTED_CATEGORY.value)?.id
            await moveIncidentsToCategory(latestIncident.incidentCategory.id, destinationCategoryId)
          }

          // Update images if they have changed
          if (areImagesChanged()) {
            // Delete old images linked to the incident
            const existingImages = latestIncident.files?.entities?.filter(file => file.type === 'image') || []
            const existingImageIds = existingImages.map(file => file.id)
            if (existingImageIds.length > 0) {
              await deleteIncidentFiles(updatedIncident.id, existingImageIds)
            }

            // Resize and upload new images
            let imageFileBlobs = await Promise.all(imageFiles.value.map(file => resizeImage(file)))
            let imageResponse = await postMultipleFiles(
              imageFileBlobs,
              imageFiles.value.map(file => file.name)
            )
            const fileIdsArray = imageResponse.map(file => file.id)
            await linkIncidentToImage(updatedIncident.id, fileIdsArray)
          }

          // Show success message
          Swal.close()
          Swal.fire({
            icon: 'success',
            title: 'Incident Updated',
            text: 'The incident has been successfully updated.',
            confirmButtonColor: '#d2f8d2',
            confirmButtonText: 'OK',
          }).then(() => {
            refresh()
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Incident Update Failed',
            text: 'Incident update failed. Please try again.',
          }).then(() => {
            showLoader.value = false
            refresh()
          })
        }
      } catch (error) {
        console.error('Error in editIncident:', error)
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred. Please try again.',
        }).then(() => {
          showLoader.value = false
          refresh()
        })
      } finally {
        showLoader.value = false
      }
    }

    const submitIncident = async () => {
      showLoader.value = true
      createDialog.value = false
      isEditMode.value = false

      if (!SELECTED_BUILDING.value || !SELECTED_CATEGORY.value || !description.value || !SELECTED_FLOOR.value || !location.value) {
        Swal.fire({
          icon: 'error',
          title: 'Missing Information',
          text: 'Please ensure all required fields are filled out correctly.',
        })
        showLoader.value = false
        return
      }

      Swal.fire({
        title: 'Creating Incident...',
        text: 'Please wait while we create the incident.',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading()
        },
      })

      try {
        let categoryId = allIncidentCategories.value.find(category => category.name === SELECTED_CATEGORY.value)?.id

        let payload = {
          buildingId: SELECTED_BUILDING.value.id,
          incidentCategoryId: categoryId,
          description: description.value,
          location: {
            floorId: SELECTED_FLOOR.value.id,
            latitude: location.value.lat,
            longitude: location.value.long,
            locationName: locationText.value,
          },
        }

        let incident = await createIncident(payload)

        if (incident && incident.id) {
          if (imageFiles.value.length > 0) {
            let imageFileBlobs = await Promise.all(imageFiles.value.map(file => resizeImage(file)))
            let imageResponse = await postMultipleFiles(
              imageFileBlobs,
              imageFiles.value.map(file => file.name)
            )
            const fileIdsArray = imageResponse.map(file => file.id)
            await linkIncidentToImage(incident.id, fileIdsArray)
          }

          Swal.close()
          Swal.fire({
            icon: 'success',
            title: 'Incident Created',
            text: 'The incident has been successfully created.',
            confirmButtonColor: '#d2f8d2',
            confirmButtonText: 'OK',
          }).then(() => {
            refresh()
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Incident Creation Failed',
            text: 'Incident creation failed. Please try again.',
          })
        }
      } catch (error) {
        console.error('Error in submitIncident:', error)
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred. Please try again.',
        })
      } finally {
        showLoader.value = false
      }
    }

    const goBack = () => {
      window.history.back()
    }

    return {
      logo,
      isMobile,
      incidents,
      hasIncidents,
      createDialog,
      SELECTED_CATEGORY,
      description,
      categories,
      openCreateDialog,
      originalValues,
      openMap,
      imageFiles,
      cancelIncident,
      submitIncident,
      editIncident,
      locationText,
      handleIncident,
      goBack,
      imageSrcDesktop,
      imageSrcMobile,
      resizeImage,
      oAuthService,
      selectedIncident,
      setLocation,
      showLoader,
      getImageURL,
      mapView,
      SELECTED_BUILDING,
      refresh,
      deleteIncident,
      canSubmit,
      router,
      SELECTED_FLOOR,
      isEditMode,
    }
  },
})
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.custom-toolbar {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  color: #333;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.custom-toolbar-title {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #333;
  margin-left: 10px;
}

.toolbar-icon {
  height: 30px;
}

.card {
  background: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  transition: all 0.3s ease;
}

.card:hover {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.thumbnail-image {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s ease;
}

.thumbnail-image:hover {
  transform: scale(1.05);
}
</style>
<!--
Incidents Component

This component allows users to report incidents or damages to facility management. The user interface is designed to be intuitive and user-friendly, providing various functionalities to enhance the user experience.

Toolbar
The toolbar at the top of the component includes:
- A back button represented by an arrow icon that allows users to navigate back to the previous page.
- A title displaying "Incidents".
- A refresh button that reloads the incidents list when clicked.
- A create button that opens a dialog for creating a new incident.

Incident List
Below the toolbar, the component displays a list of user incidents using the UserIncidents component. Each incident can be clicked to view or edit its details.

Loader
A Loader component is used to indicate loading states, ensuring users are aware when data is being fetched or processed.

Create/Edit Incident Dialog
When the create button is clicked, a dialog opens allowing users to create a new incident. If an incident is selected from the list, the dialog opens in edit mode, allowing users to update the incident details. The dialog includes:
- A title and subtitle explaining the purpose of the form.
- A form with input fields for setting the location, selecting a category, describing the incident, and uploading images.
- A map view for selecting the location.
- Buttons to cancel, delete (in edit mode), submit, or update the incident.

Form Fields
- Location: A read-only text field that opens a map view when clicked, allowing users to select a location.
- Category: A dropdown menu for selecting the incident category.
- Description: A textarea for describing the incident.
- Image Upload: A file input for uploading images related to the incident. Selected images are displayed as chips and thumbnails.

Visual Feedback
The component uses Swal (SweetAlert2) for visual feedback, providing alerts for various actions:
- Loading: A loading alert is displayed while data is being fetched or processed.
- Success: A success alert is shown when an incident is successfully created, updated, or deleted.
- Error: An error alert is displayed if an action fails, informing users of the issue.
- Confirmation: A confirmation dialog is shown before deleting an incident, asking users to confirm their action.

Dynamic Behaviors
- The create/edit dialog dynamically updates based on whether the user is creating a new incident or editing an existing one.
- The form fields are validated, and the submit/update button is disabled until all required fields are filled out.
- The map view allows users to select a location, which is then reflected in the location text field.
- Image files are resized before uploading to ensure they meet size requirements.

Overall, this component provides a comprehensive interface for managing incidents, offering users a seamless experience with clear visual feedback and dynamic interactions.
-->

<template>
  <div>
    <Loader :loading="showLoader" />
    <v-dialog ref="dialogRef" persistent width="fit-content" v-model="showMap" :key="componentKey">
      <Loader :loading="loadingBookings" />
      <v-card v-show="showMap">
        <v-card-title class="initial lighten-2" style="font-size: 1rem; font-weight: bold">
          {{ 'Map View' }}
          <v-btn v-if="!dragMarker" style="position: absolute; right: 10px" @click="showMap = false" size="small"
            color="red" variant="tonal">
            <v-icon size="large">mdi-close</v-icon>
            <span>CLOSE</span>
          </v-btn>
          <v-btn v-else style="position: absolute; right: 10px" @click="saveMarkerPosition" size="small" color="green"
            variant="tonal">
            <v-icon size="large">mdi-check</v-icon>
            <span>SAVE</span>
          </v-btn>
        </v-card-title>

        <!-- <div class="text-center" style="position: absolute; top: 70px; right: 20px; padding: 10px; z-index: 400">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="white" dark v-bind="attrs" v-on="on" style="border-radius: 20px">
                <span style="color: black; font-size: 0.7rem; font-weight: bold; margin-right: 0.4rem">
                  {{ $t('map.floor') + ' ' + selectedFloor?.name }}
                </span>
                <v-icon color="black" style="font-size: 1.2rem">mdi-layers</v-icon>
              </v-btn>
            </template>
<v-list rounded style="max-height: 300px; overflow-y: auto">
  <v-list-item-group v-model="selectedFloorNumber" color="primary">
    <v-list-item v-for="(item, i) in lodash.uniqBy(lodash.orderBy(floors, ['number'], ['asc']))" :key="i"
      @click="changeFloor(item)">
      <v-list-item-content>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-item-group>
</v-list>
</v-menu>
</div> -->
        <div class="text-center" style="position: absolute; top: 70px; right: 20px; padding: 10px; z-index: 400">
          <v-menu :location="'start'">
            <template v-slot:activator="{ props }">
              <v-btn color="white" dark v-bind="props">
                {{ selectedFloor.name }}
                <v-icon color="black" style="font-size: 1.2rem">mdi-layers</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-for="(item, index) in lodash.uniqBy(lodash.orderBy(floors, ['number'], ['asc']))"
                :key="index" @click="changeFloor(item)">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="text-center" style="position: absolute; bottom: 50px; right: 20px; padding: 10px; z-index: 400"
          @click="openRangeDatePicker">
          <v-btn id="availablity" class="mx-2" fab :color="!showAvailability ? 'white' : 'black'" dark small>
            <v-icon :style="!showAvailability ? 'color:black' : 'color:white'">mdi-calendar-clock</v-icon>
          </v-btn>
        </div>

        <l-map ref="map" v-if="showMap && selectedFloor?.floorPlan && selectedFloor?.bounds" @load="adjustMapSize"
          @ready="onMapReady" @mousedown="startMapDrag" @mouseup="endMapDrag" :setView="selectedFloor.center"
          :style="{ zIndex: 0, width: dialogWidth + 'vw', height: dialogHeight + 'vh' }" :noBlockingAnimations="true"
          :maxZoom="25" :zoom="zoom" :center="floorCenter" :bounds="selectedFloor?.bounds">
          <l-tile-layer :url="url" :attribution="attribution" />
          <l-image-overlay :zoom="25" :url="selectedFloor?.floorPlan" :bounds="selectedFloor?.bounds" />

          <l-marker v-for="marker in lodash.uniqBy(markers, 'poiId')" :key="marker.poiId" :lat-lng="marker.latlong"
            :icon="getMarkerIcon(marker)">
            <l-tooltip :permanent="true" direction="top">
              {{ marker.poi.name }}
            </l-tooltip>
            <l-popup>
              <v-card-title style="font-size: 0.9rem; font-weight: bold">
                {{ marker?.poi?.name }}
              </v-card-title>
              <v-card-subtitle>
                {{ marker?.category?.name + ', ' + selectedFloor.name }}
              </v-card-subtitle>
              <v-btn @click="goToPoiDetails(marker?.poi)" block elevation="2" x-small
                v-if="$route.name !== 'poiDetails'">Details</v-btn>
            </l-popup>
          </l-marker>
          <l-marker v-if="dragMarker" :lat-lng="markerLatLng" :draggable="true" @dragend="updateMarkerPosition" />

          <l-polygon v-for="(polygon, index) in lodash.uniqBy(polygons, 'poiId')" :key="index"
            :lat-lngs="polygon.latLng" :color="polygon.color" :fill-color="polygon.color" :fill-opacity="0.5"
            :weight="polygon.weight"></l-polygon>
        </l-map>
      </v-card>

      <v-dialog v-model="availabilityDialog" persistent max-width="300px">
        <v-card style="background-color: #1a202c">
          <v-card-title></v-card-title>
          <v-card-text>
            <DatePicker :validHours="validHours" :max-date="maxDate" ref="rangeDatePicker" color="red" is-dark
              :is24hr="true" :min-date="new Date()" :minute-increment="15" is-range v-model="range" mode="dateTime">
            </DatePicker>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn plain color="white" style="background-color: #ff5252" text @click="discardRange">Cancel</v-btn>
            <v-btn plain style="background-color: #4caf50; color: white" @click="confirmRange">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import { LMap, LTileLayer, LMarker, LImageOverlay, LIcon, LPopup, LPolygon, LTooltip } from '@vue-leaflet/vue-leaflet'
import { ref, defineComponent, nextTick, computed } from 'vue'
import { getMarkerIcon } from '@/services/markerIcons'
import lodash from 'lodash'
import { DatePicker } from 'v-calendar'
import Loader from '@/components/general/Loader'
import { getFile, getDataFromBlob, getFloors } from '@/controllers/incidentsAxiosBypasser'
import { getReservationsByPoi } from '@/controllers/BaseController'
import { latLngBounds, latLng } from 'leaflet'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'MapView',
  props: {
    dragMarker: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LMap,
    LTooltip,
    Loader,
    DatePicker,
    LTileLayer,
    LMarker,
    LImageOverlay,
    LPopup,
    LPolygon,
  },
  setup(props, { emit }) {
    const showMap = ref(false)
    const zoom = ref(22)
    const availabilityDialog = ref(false)
    const markerLatLng = ref([51.505, -0.09]) // Default center
    const showAvailability = ref(false)
    const validHours = ref({ min: 6, max: 20 })
    const loadingBookings = ref(false)
    const selectedMarker = ref({})

    const markers = ref([])
    const polygons = ref([])
    const url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    const attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    const selectedFloor = ref({})
    const floorCenter = ref({})
    const dialogWidth = ref(80)
    const dialogHeight = ref(80)
    const map = ref(null) // Initialize ref with null
    const markerIconUrl = 'https://uxwing.com/wp-content/themes/uxwing/download/location-travel-map/maps-pin-line-icon.png'
    const showLoader = ref(false)
    const floors = ref([])
    const selectedBuilding = ref({})
    const categories = ref([])
    const pois = ref([])
    const route = useRoute()
    const router = useRouter()

    const getInitialDateRange = () => {
      const startDate = new Date()
      const endDate = new Date(startDate)
      endDate.setMinutes(startDate.getMinutes() + 10)

      if (endDate.getHours() >= 20 && endDate.getMinutes() > 0) {
        startDate.setDate(startDate.getDate() + 1)
        startDate.setHours(6, 0, 0, 0)
        endDate.setDate(endDate.getDate() + 1)
        endDate.setHours(6, 10, 0, 0)
      }

      return { start: startDate, end: endDate }
    }
    const range = ref(getInitialDateRange())
    const tempRange = ref(getInitialDateRange())

    const maxDate = computed(() => {
      const today = new Date()
      return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 57)
    })
    const openRangeDatePicker = () => {
      availabilityDialog.value = true
      tempRange.value = range.value
    }

    const discardRange = () => {
      availabilityDialog.value = false
      range.value = tempRange.value
    }

    const confirmRange = async () => {
      availabilityDialog.value = false
      tempRange.value = range.value
      await handleAvailability()
    }

    const handleAvailability = async () => {
      loadingBookings.value = true
      const startDateTime = range.value.start
      const endDateTime = range.value.end

      const updatedPolygons = await Promise.all(
        polygons.value.map(async polygon => {
          const poi = polygon.poi
          const category = lodash.find(categories.value, category => category?.id === poi?.poiCategories?.ids[0])
          const reservations = await getReservationsByPoi(poi?.id, startDateTime, endDateTime)
          // if (poi?.name?.toLowerCase().includes('enteractive')) {
          //   reservations.push({ start: new Date(), end: new Date() })
          // }
          // console.log(reservations)
          if (reservations.length === 0) {
            polygon.fillColor = '#71aca2'
            polygon.color = '#71aca2'
          } else {
            polygon.fillColor = 'red'
            polygon.color = 'red'
          }
          return polygon
        })
      )
      polygons.value = JSON.parse(JSON.stringify(updatedPolygons))
      setTimeout(() => {
        showAvailability.value = true
        loadingBookings.value = false
      }, 800)
    }

    async function openMap(selectedBuildingParam, poisParam, categoriesParam, markerSelected = null) {
      showLoader.value = true
      floors.value = []
      selectedBuilding.value = selectedBuildingParam
      pois.value = lodash.filter(poisParam, item => item.buildingId === selectedBuildingParam.id)
      categories.value = categoriesParam

      try {
        let fetchedFloors = await getFloors()
        if (markerSelected) {
          selectedMarker.value = markerSelected
        }
        fetchedFloors = lodash.filter(fetchedFloors, floor => {
          if (!floor.building || !floor.northEastCorner || !floor.southWestCorner) {
            console.warn('Skipping floor due to missing properties:', floor)
            return false
          }
          return floor.building.id === selectedBuildingParam.id && floor.active
        })

        if (categories.value?.length === 1) {
          const categoryPoiIds = new Set(categories.value[0].pois.ids)
          fetchedFloors = fetchedFloors.filter(floor => floor.pois.ids.some(poiId => categoryPoiIds.has(poiId)))
        }

        fetchedFloors = fetchedFloors.sort((a, b) => a.number - b.number)

        const floorIds = new Set(pois.value.map(poi => poi?.floors?.ids[0]))
        fetchedFloors = fetchedFloors.filter(floor => floorIds.has(floor.id))

        if (route.name === 'poiDetails') {
          fetchedFloors = [pois.value[0]?.floor]
          floors.value = fetchedFloors
        }

        const floorPromises = fetchedFloors.map(async (floor, index) => {
          let floorPlan = floor.configs?.floorplan
          if (!floor.northEastCorner || !floor.southWestCorner) {
            console.error('Missing corner coordinates for floor:', floor)
            return
          }
          floor['bounds'] = latLngBounds([
            [floor.northEastCorner.lat, floor.northEastCorner.long],
            [floor.southWestCorner.lat, floor.southWestCorner.long],
          ])
          floor['center'] = latLng(floor.northEastCorner.lat, floor.northEastCorner.long)
          if (floorPlan) {
            floorPlan = await getFile(floorPlan)
            floorPlan = await getDataFromBlob(floorPlan)
            floor['floorPlan'] = floorPlan
            floors.value.push(floor)
          } else {
            floor['floorPlan'] = null
            floors.value.push(floor)
          }
          if (index === 0) {
            selectedFloor.value = floor
            getMarkers()
          }
        })

        await Promise.all(floorPromises)
      } catch (error) {
        console.error('Error in openMap:', error)
      } finally {
        showLoader.value = false
        showMap.value = true
      }
    }

    const changeFloor = floor => {
      selectedFloor.value = floor
      getMarkers()
      // this.markers = [];
      // this.selectedFloor = floor;
      // this.selectedFloorNumber = floor.number;
      // this.getMarkers();
    }

    function adjustMapSize() {
      nextTick(() => {
        setTimeout(() => {
          if (map.value && map.value.mapObject) {
            map.value.mapObject.invalidateSize()
          }
        }, 100) // wait an additional 100ms
      })
    }

    function onMapReady() {
      adjustMapSize()
    }
    const updateMarkerPosition = event => {
      // Update the markerLatLng with the new position after dragging stops
      markerLatLng.value = [event.target.getLatLng().lat, event.target.getLatLng().lng]
    }

    function getMarkers() {
      let poisData = pois.value
      let categoriesData = categories.value
      markers.value = []
      polygons.value = []
      poisData.forEach(poi => {
        if (!poi.locations || !poi.locations[0]) {
          console.error('Skipping POI due to missing location data:', poi)
          return
        }
        let category = lodash.find(categoriesData, category => category?.id === poi?.poiCategories?.ids[0])
        let marker = {
          poiId: poi?.id,
          poi: poi,
          category: category,
          latlong: [poi.locations[0].center.lat, poi.locations[0].center.long],
        }
        if (marker && poi?.floors?.ids[0] === selectedFloor.value?.id) {
          markers.value.push(marker)
        }

        poi.locations.forEach(location => {
          if (!location.polygon) {
            console.error('Skipping location due to missing polygon data:', location)
            return
          }
          let polygon = location.polygon
          let poiPolygon = {
            poi: poi,
            latLng: undefined,
            color: undefined,
            poiId: poi?.id,
            weight: 0,
            fillColor: 'green',
          }

          poiPolygon.latLng = polygon
            .map(point => {
              if (!point.lat || !point.long) {
                console.error('Skipping point due to missing lat/long:', point)
                return null
              }
              return [point.lat, point.long]
            })
            .filter(Boolean) // Remove null values

          poiPolygon.color = '#3EB39F'

          if (poiPolygon && poi?.floors?.ids[0] === selectedFloor.value?.id) {
            const latLngExists = polygons.value.some(
              existingPolygon => JSON.stringify(existingPolygon.latLng) === JSON.stringify(poiPolygon.latLng)
            )

            if (!latLngExists) {
              polygons.value.push(poiPolygon)
            }
          }
        })
      })
      if (markers.value.length === 1) {
        floorCenter.value = markers.value[0].latlong
      } else {
        floorCenter.value = selectedFloor.value.bounds.getCenter()
      }
      if (props.dragMarker) {
        console.log(props.dragMarker)
        const closestMarker = floorCenter.value // Example: choose the first marker
        markerLatLng.value = markers.value[0].latlong // Set the draggable marker near the closest one
        if (selectedMarker.value && selectedMarker.value.lat && selectedMarker.value.long) {
          markerLatLng.value = latLng(selectedMarker.value.lat, selectedMarker.value.long)
        }
      }

      polygons.value = lodash.uniq(polygons.value, 'poiId')
    }

    // function getMarkerIcon(marker) {
    //   let markerType = marker?.category?.tags['poi-category-icon-name']
    //   if (markerType === 'supervisor_account') {
    //     return 'mdi-account-multiple'
    //   }
    //   return null
    // }

    const saveMarkerPosition = () => {
      const markerPosition = markerLatLng.value
      const floor = selectedFloor.value

      // Calculate the locationName based on polygons and marker position
      const locationName = getLocationName(markerPosition, polygons.value, floor)

      // Emit the position, floor, and calculated locationName
      emit('onPositionSelected', {
        coordinates: markerPosition,
        floor: floor,
        locationName: locationName, // Include locationName
      })
      showMap.value = false
    }

    const getLocationName = (markerLatLng, polygons, selectedFloor) => {
      let locationName = ''
      let isInsidePolygon = false
      let closestPolygon = null
      let closestDistance = Infinity

      // Iterate through polygons to check if the marker is inside any polygon
      polygons.forEach(polygon => {
        const bounds = latLngBounds(polygon.latLng)

        // Check if the marker is inside the polygon bounds
        if (bounds.contains(markerLatLng)) {
          locationName = `Near ${polygon.poi.name}, Floor: ${selectedFloor.name}`
          isInsidePolygon = true
        }
      })

      // If the marker is not inside any polygon, find the closest polygon
      if (!isInsidePolygon) {
        polygons.forEach(polygon => {
          const bounds = latLngBounds(polygon.latLng)
          const polygonCenter = bounds.getCenter()
          const distance = latLng(markerLatLng).distanceTo(polygonCenter)

          // Find the closest polygon based on distance
          if (distance < closestDistance) {
            closestDistance = distance
            closestPolygon = polygon
          }
        })

        // If no polygon contains the marker, use the closest polygon
        if (closestPolygon) {
          locationName = `Near ${closestPolygon.poi.name}, Floor: ${selectedFloor.name}`
        }
      }

      return locationName
    }

    function goToPoiDetails(poi) {
      router
        .push({
          name: 'PoiDetails',
          params: { building_id: poi.building.id, poi_id: poi?.id },
        })
        .catch(() => { })
    }

    return {
      showMap,
      zoom,
      markers,
      polygons,
      url,
      attribution,
      selectedFloor,
      dialogWidth,
      dialogHeight,
      map,
      markerIconUrl,
      showLoader,
      floors,
      selectedBuilding,
      categories,
      pois,
      openMap,
      adjustMapSize,
      onMapReady,
      getMarkers,
      goToPoiDetails,
      lodash,
      changeFloor,
      getMarkerIcon,
      floorCenter,
      showAvailability,
      markerLatLng,
      availabilityDialog,
      range,
      validHours,
      maxDate,
      openRangeDatePicker,
      discardRange,
      confirmRange,
      handleAvailability,
      loadingBookings,
      saveMarkerPosition,
      props,
      updateMarkerPosition,
    }
  },
})
</script>

<style scoped lang="scss">
@import '~leaflet/dist/leaflet.css';

.leaflet-bottom.leaflet-right .leaflet-control-attribution {
  display: none !important;
}

.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}

.mapView {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 600px;
  height: 800px;

  .leaflet-image-layer .leaflet-zoom-animated {
    width: 110px !important;
    height: 103px !important;
  }
}

.v-dialog {
  &:hover {
    cursor: nwse-resize; // Diagonal resize cursor
  }
}

.floorSelector {
  min-width: 3rem !important;
  border-radius: 30px !important;
  cursor: pointer;
  margin-left: 4rem;
  margin-top: 0.2rem;
}
</style>

<!-- IncidentsMapView Component

This component provides a detailed map view for users to interact with various points of interest (POIs) and floor plans within a building. The main functionalities and user interactions are as follows:

1. Loader: Displays a loading spinner when data is being fetched or processed, providing visual feedback to the user.

2. Map Dialog: A dialog box that contains the map view. It can be opened or closed using buttons. The dialog is persistent and adjusts its size dynamically.

3. Map View Title: Displays the title "Map View" with buttons to either close the map or save the marker position if the dragMarker prop is enabled.

4. Floor Selector: A button that allows users to select different floors of the building. Clicking the button opens a menu with a list of available floors. Users can click on a floor name to change the map view to that floor.

5. Availability Button: A button at the bottom right corner that opens a date range picker dialog. Users can select a date and time range to check the availability of POIs. The button changes color based on the availability status.

6. Map: The main map area where users can see the floor plan, markers, and polygons representing different POIs. Users can interact with the map by dragging markers (if enabled), clicking on markers to see details, and viewing tooltips and popups for more information.

7. Date Range Picker Dialog: A dialog that allows users to select a date and time range. It provides options to cancel or confirm the selection. Upon confirmation, the availability of POIs is checked and displayed on the map.

8. Visual Feedback: The component provides visual feedback through loading spinners, button color changes, and map updates. For example, when checking availability, the polygons on the map change color to indicate available or unavailable POIs.

9. Dynamic Behaviors: The component dynamically updates the map view based on user interactions, such as changing floors, dragging markers, and selecting date ranges. It also adjusts the map size to fit the dialog dimensions.

10. Error Handling: The component includes error handling for missing data and provides console warnings or errors to help developers debug issues.

Overall, this component enhances the user experience by providing an interactive and informative map view with various functionalities to explore and manage POIs within a building. Users can leverage this component to navigate through different floors, check availability, and get detailed information about specific POIs.

-->
